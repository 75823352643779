import _ from 'lodash';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
export class RoteiroExecutarRecebimentoSemAgendamentoModel {
  constructor({
    contatoSolicitante,
    quantidadeVolumes,
    cliente
  }) {
    this.contatoSolicitante = contatoSolicitante;
    if (contatoSolicitante)
      this.contatoSolicitante = new DropdownModel(contatoSolicitante)
    this.quantidadeVolumes = quantidadeVolumes
    this.cliente = cliente
    if(cliente)
      this.cliente = new DropdownModel(cliente)


  }

  get request() {
    let retorno = _.cloneDeep(this);

    retorno.contatoSolicitanteId = retorno.contatoSolicitante?.value;


    return retorno;
  }
}
