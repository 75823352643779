var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-space-between",attrs:{"cols":"12"}},[_c('span',{staticClass:"titulo-pagina"},[_vm._v(" "+_vm._s(_vm.tituloFormulario)+" ")])])],1),_c('v-form',{ref:"form",staticClass:"row"},[(!_vm.veioDoVisualizarRoteiro)?_c('input-text',{staticClass:"col-12",attrs:{"label":_vm.$t(
          'modulos.roteiro_execucao_envio_material.recebimentoSemAgendamento.cliente'
        ),"disabled":""},model:{value:(_vm.nomeCliente),callback:function ($$v) {_vm.nomeCliente=$$v},expression:"nomeCliente"}}):_c('input-cliente',{staticClass:"col-12 col-md-4",attrs:{"label":_vm.$t('modulos.orcamentos.formulario.cliente'),"obrigatorio":""},model:{value:(_vm.form.cliente),callback:function ($$v) {_vm.$set(_vm.form, "cliente", $$v)},expression:"form.cliente"}}),_c('input-select',{staticClass:"col-12",attrs:{"label":_vm.$t(
          'modulos.roteiro_execucao_envio_material.recebimentoSemAgendamento.contato'
        ),"placeholder":_vm.$t('geral.inputs.selecione'),"input-adicionar":true,"obrigatorio":"","com-botao-adicionar":"","options":_vm.opcoes.contato,"retornar-objeto":""},scopedSlots:_vm._u([{key:"botaoAdicionar",fn:function(){return [_c('botao-padrao',{attrs:{"botao-adicionar-input":true},on:{"click":_vm.abrirCadastroParticipante}},[_c('span',{staticStyle:{"font-size":"23px"}},[_vm._v(" + ")])])]},proxy:true}]),model:{value:(_vm.form.contatoSolicitante),callback:function ($$v) {_vm.$set(_vm.form, "contatoSolicitante", $$v)},expression:"form.contatoSolicitante"}}),_c('input-text',{staticClass:"col-12",attrs:{"label":_vm.$t(
          'modulos.roteiro_execucao_envio_material.recebimentoSemAgendamento.qtdVolumes'
        ),"type":"number","obrigatorio":"","numero-minimo":"0"},model:{value:(_vm.form.quantidadeVolumes),callback:function ($$v) {_vm.$set(_vm.form, "quantidadeVolumes", $$v)},expression:"form.quantidadeVolumes"}})],1),_c('v-divider',{staticClass:"my-5"}),[_c('div',{staticClass:"d-flex align-center justify-center justify-md-end"},[_c('botao-padrao',{staticClass:"mr-2 flex flex-grow-1 flex-md-grow-0",attrs:{"outlined":"","color":"secondary","btn100":""},on:{"click":_vm.cancelar}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" $mdiCancel ")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.cancelar'))+" ")],1),_c('botao-padrao',{staticClass:"flex-grow-1 flex-md-grow-0",attrs:{"btn100":""},on:{"click":_vm.recebimentoSemAgendamento}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" $mdiContentSaveOutline ")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.salvar'))+" ")],1)],1)],_c('contato-modal',{ref:"contato-modal",attrs:{"participante-id":_vm.clienteDaVez.id},on:{"atualizar-contato":_vm.atualizarContato}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }