<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="d-flex align-center justify-space-between"
      >
        <span class="titulo-pagina">
          {{ tituloFormulario }}
        </span>
      </v-col>
    </v-row>
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-if="!veioDoVisualizarRoteiro"
        v-model="nomeCliente"
        :label="
          $t(
            'modulos.roteiro_execucao_envio_material.recebimentoSemAgendamento.cliente'
          )
        "
        class="col-12"
        disabled
      />
      <input-cliente
        v-else
        v-model="form.cliente"
        class="col-12 col-md-4"
        :label="$t('modulos.orcamentos.formulario.cliente')"
        obrigatorio
      />
      <input-select
        v-model="form.contatoSolicitante"
        :label="
          $t(
            'modulos.roteiro_execucao_envio_material.recebimentoSemAgendamento.contato'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
        :input-adicionar="true"
        obrigatorio
        com-botao-adicionar
        class="col-12"
        :options="opcoes.contato"
        retornar-objeto
      >
        <template #botaoAdicionar>
          <botao-padrao
            :botao-adicionar-input="true"
            @click="abrirCadastroParticipante"
          >
            <span style="font-size: 23px"> + </span>
          </botao-padrao>
        </template>
      </input-select>
      <input-text
        v-model="form.quantidadeVolumes"
        :label="
          $t(
            'modulos.roteiro_execucao_envio_material.recebimentoSemAgendamento.qtdVolumes'
          )
        "
        class="col-12"
        type="number"
        obrigatorio
        numero-minimo="0"
      />
    </v-form>
    <v-divider class="my-5" />

    <template>
      <div class="d-flex align-center justify-center justify-md-end">
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2 flex flex-grow-1 flex-md-grow-0"
          btn100
          @click="cancelar"
        >
          <v-icon class="mr-1">
            $mdiCancel
          </v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao
          class="flex-grow-1 flex-md-grow-0"
          btn100
          @click="recebimentoSemAgendamento"
        >
          <v-icon class="mr-1">
            $mdiContentSaveOutline
          </v-icon>
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>
      </div>
    </template>
    <contato-modal
      ref="contato-modal"
      :participante-id="clienteDaVez.id"
      @atualizar-contato="atualizarContato"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { InputCliente } from '@components/inputs';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import {
  ParticipanteService,
  RoteiroEnvioMaterialService,
} from '@/common/services/cadastros';
import ContatoModal from './modal/ContatoParticiapante';
import { RoteiroExecutarRecebimentoSemAgendamentoModel } from '@/common/models/cadastros/RoteiroExecutarRecebimentoSemAgendamentoModel';
export default {
  components: {
    ContatoModal,
    InputCliente,
  },
  data() {
    return {
      form: new RoteiroExecutarRecebimentoSemAgendamentoModel({}),
      opcoes: {
        contato: [],
      },
      nomeCliente: '',
      cliente: {},
    };
  },
  computed: {
    ...mapGetters('Roteiro', [
      'clienteDaVez',
      'roteiroId',
      'ultimoClienteCheckout',
    ]),
    tituloFormulario() {
      return this.$t(
        'modulos.roteiro_execucao_envio_material.titulos.recebimentoSemAgendamento'
      );
    },
    veioDoVisualizarRoteiro() {
      return !!this.$route.query?.ultimoCheckout;
    },
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t(
        'modulos.roteiro_execucao_envio_material.titulos.recebimentoSemAgendamento'
      )
    );

    if (this.veioDoVisualizarRoteiro) {
      this.form.cliente = new DropdownModel(this.ultimoClienteCheckout);
      this.atualizarContato(this.ultimoClienteCheckout.id);
    } else {
      this.nomeCliente = this.clienteDaVez.nome;
      this.opcoes.contato = this.clienteDaVez.contatos.map(
        (contato) => new DropdownModel(contato)
      );
    }
    if (!this.form.contatoSolicitante)
      this.form.contatoSolicitante = this.opcoes.contato[0];
  },
  methods: {
    abrirCadastroParticipante: function () {
      this.$refs['contato-modal'].abrirModal();
    },
    atualizarContato: function (id) {
      ParticipanteService.buscar(id || this.clienteDaVez.id).then((res) => {
        this.opcoes.contato = res.data.contatos.map(
          (contato) => new DropdownModel(contato)
        );
        this.form.contatoSolicitante = this.opcoes.contato.at(-1);
      });
    },
    recebimentoSemAgendamento: function () {
      const valido = this.$refs.form.validate();
      if (!valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      RoteiroEnvioMaterialService.recebimentoSemAgendamento(
        this.roteiroId,
        this.form.cliente?.value || this.clienteDaVez.id,
        this.form.request
      )
        .then(() => {
          this.toastSucesso(
            this.$t(
              `modulos.roteiro_execucao_envio_material.mensagem.recebimentoSemAgendamentoSucesso`
            )
          );
          this.$router.push({
            name: 'roteiro-executar-envio-material-checkin',
          });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'roteiro-executar-envio-material-checkin' });
      });
    },
  },
};
</script>
<style lang=""></style>
